<template>
  <div class="real-time-data-container">
    <div class="title">
      <div style="display: flex; padding-bottom: 10px">
        <a-avatar shape="square" :size="40" :src="avatar" icon="user" />
        <div style="padding-left: 6px">
          <div>{{ userInfo.company || userInfo.username || '--' }}</div>
          <div>{{ userInfo.loginId || '--' }}</div>
        </div>
      </div>
      <!-- <a-button type="primary">
        <router-link :to="{ name: 'money' }">充值</router-link>
      </a-button> -->
    </div>
    <div class="container">
      <HomeTitle title="实际投放数据" :borderColor="'#f40'" />
      <a-row class="row" align="middle" :gutter="10" type="flex">
        <a-col v-for="(item, i) in cardList" :key="i" class="col-item">
          <CardItem
            :title="item.label"
            :value="dataList[item.key] || ''"
            :borderColor="item.color"
            :propor="dataList[item.propor] || 0"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { summaryData } from '@/api/agentadvertiser'
import HomeTitle from '../components/homeTitle'
import CardItem from './realCardItem.vue'
import mixDate from '@/mixins/initDate'
import { mapState, mapGetters } from 'vuex'
export default {
  components: { CardItem, HomeTitle },
  mixins: [mixDate],
  data () {
    return {
      dataList: [],
      cardList: [
        {
          propor: 'impressRatio',
          key: 'impress',
          label: '展示数据',
          color: '#ebb657'
        },
        {
          propor: 'clickRatio',
          key: 'click',
          label: '点击数',
          color: '#427aee'
        },
        {
          propor: 'adplanNumRatio',
          key: 'adplanNum',
          label: '投放计划数',
          color: '#e230ad'
        },
        {
          propor: 'costRatio',
          key: 'cost',
          label: '今日消费金额',
          color: '#74a0e9'
        },
        {
          propor: 'accountBalanceRatio',
          key: 'accountBalance',
          label: '账户余额',
          color: '#49e7c7'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile
    }),
    ...mapGetters(['userInfo', 'avatar'])
  },
  mounted () {
    const { endDate, startDate } = this.initDate(0, 0)
    this.summaryDataFn({ startDate, endDate })
  },
  methods: {
    async summaryDataFn (data) {
      const resp = await summaryData(data)
      if (resp.code === 200 && resp.data) {
        this.dataList = resp.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.real-time-data-container {
  background: #fff;
  height: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 10px 20px 20px;
  .title {
    width: 200px;
    min-width: 200px;
  }
  .container {
    flex: 2;
    padding-left: 30px;
  }
  .row {
    flex-flow: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 15px;
    .col-item {
      position: relative;
      width: 20%;
      min-width: 160px;
      margin-right: 10px;
    }
  }
}
</style>
