import request from '@/utils/request'
// 获取代理商及其广告主的5条计划投放情况
export function topAdplan (data) {
  return request({
    url: '/agentadvertiser/topAdplan',
    method: 'post',
    data
  })
}
// 获取代理商及其广告主今天或者昨天的投放数据
export function launchsituation (data) {
  return request({
    url: '/agentadvertiser/launchsituation',
    method: 'post',
    data
  })
}
// 获取代理商及其广告主的7天或者30天的投放数据
export function newlaunchsituation (data) {
  return request({
    url: '/agentadvertiser/launchsituation',
    method: 'post',
    data
  })
}
// 获取代理商及其广告主当天的投放情况
export function summaryData (data) {
  return request({
    url: '/agentadvertiser/summaryData',
    method: 'post',
    data
  })
}
