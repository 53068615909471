<template>
  <div class="card-item-container">
    <div class="title-box">
      <span class="title">{{ title }}</span>
    </div>
    <div class="value-box">
      <span
        class="value"
      >{{ numFormat(value, 3, '') }}{{ title === '流量填充率' ? '%' : title === '展示率' ? '%' : '' }}</span
      >
      <div class="circle" :style="{ borderColor: borderColor }"></div>
    </div>
    <div class="propor-box">
      <div class="propor-title">环比</div>
      <span
        :class="{ propor: true, increase: propor < 0, decrease: propor > 0 }"
      >{{ propor > 0 ? '+' + propor : '' + propor }}%</span
      >
    </div>
  </div>
</template>

<script>
import { numFormat } from '@/utils/dealNumber'
export default {
  props: {
    title: {
      type: String,
      default: 'abc'
    },
    value: {
      type: [Number, String],
      default: 0
    },
    borderColor: {
      type: String,
      default: '#008c8c'
    },
    propor: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    numFormat
  }
}
</script>
<style lang="less" scoped>
.card-item-container {
  position: relative;
  min-height: 82px;
  background: #f9f9fc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: flex-start;
  padding-top: 5px;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
  cursor: pointer;
  div.title-box {
    height: 20px;
    line-height: 20px;
    margin-bottom: 3px;
    span.title {
      font-size: 14px;
      color: #999;
      line-height: 14px;
    }
  }
  div.value-box {
    height: 25px;
    line-height: 25px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 2px;
    span.value {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      line-height: 20px;
    }
    div.circle {
      width: 16px;
      height: 16px;
      border: 4px solid;
      border-radius: 50%;
    }
  }
  div.propor-box {
    font-size: 12px;
    .propor-title {
      font-size: 13px;
      display: inline-block;
      margin-right: 3px;
      color: #b3b3b3;
    }
    .increase {
      color: #f02388;
    }
    .decrease {
      color: #27ca8a;
    }
  }
}
</style>
